<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>To Do</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="toDoRequest.urgent"
                        label="Urgent ?"
                        :value=0
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>Assigned To</label>
                    <v-combobox
                        v-model="toDoRequest.userResponsibleSelected"
                        :items="listUser"
                        item-text="name"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        @change="getListToDoCategory(true)"
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>Category</label>
                    <v-combobox
                        v-model="toDoRequest.toDoCategorySelected"
                        :items="listToDoCategory"
                        item-text="description"
                        item-value="id"
                        clearable
                        single-line
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>Title</label>
                    <v-text-field
                        v-model="toDoRequest.title" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <v-menu
                        ref="mnuDateToDo"
                        v-model="mnuDateToDo"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label>Target Date</label>
                            <v-text-field
                                v-model="dateToDoFormatted"
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                @blur="dateToDo = parseDate(dateToDoFormatted)"
                                outlined
                                single-line
                                v-on="on"
                                dense
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dateToDo"
                            no-title
                            @input="mnuDateToDo = false"
                            color="primary lighten-1"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Projects</label>
                    <v-combobox
                        v-model="toDoRequest.projectSelected"
                        :items="listProjects"
                        item-text="description"
                        item-value="id"
                        clearable
                        outlined
                        dense
                        @change="getStatus()"
                    ></v-combobox>
                </v-col>
                <v-col
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Phase</label>
                    <v-combobox
                        v-model="toDoRequest.statusSelected"
                        :items="listStatus"
                        item-text="statusDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Description</label>
                    <v-textarea
                        v-model="toDoRequest.description"
                        :rules=[validations.required]
                        single-line
                        rows="4"
                        row-height="30"
                        outlined
                    >
                    </v-textarea>
                </v-col>
            </v-row>

            <br />

            <ActionButtons
                :request="toDoRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { orderBy } from '@/utilities/Utils';

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            assignToDoSomeoneElse: "N",
            toDoUrgent: 0,
            
            toDoRequest: {
                id: 0,
                idUserResponsible: 0,
                userResponsibleSelected: null,
                idTodoCategory: 0,
                toDoCategorySelected: null,
                urgent: 0,
                system: "0",
                assignedToMe: "0",
                title: "",
                description: "",
                action: "",
                read: "0"
            },

            dateToDo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            // dateToDoFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            dateToDoFormatted: null,
            mnuDateToDo: false,

            listProjects: [],
            listStatus: [],

            listUser: [],
            listToDoCategory: [],
            
            loading: false,

            validations: {
                required: required,
            }
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' })
        },

        watch: {
            dateToDo () {
                this.dateToDoFormatted = this.formatDate(this.dateToDo)
            },
        },

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("toDoModule/GetById", this.id);

                    this.toDoRequest = response.result;
                    const statusSelected = {...this.toDoRequest.statusSelected};

                    if (this.toDoRequest.idUserResponsible === this.userLoggedGetters.id) {
                        this.toDoRequest.userResponsibleSelected.name = 'Myself'
                    }
                    else {
                        this.toDoRequest.toDoCategorySelected.description = 'Not Applicable'
                    }

                    if (this.toDoRequest.statusSelected != null && this.toDoRequest.statusSelected != undefined && this.toDoRequest.statusSelected.id == 0) {
                        this.toDoRequest.statusSelected = {
                            id: 0,
                            statusDescription: null
                        }
                    }

                    const idServiceProject = this.toDoRequest.idServiceProject;
                    const projectSelected = this.listProjects.filter(prj => prj.id == idServiceProject)[0];
                    this.toDoRequest.projectSelected = projectSelected;
                    await this.getStatus();
                    this.toDoRequest.statusSelected = statusSelected;

                    this.dateToDoFormatted = this.toDoRequest.dateToDoFormatted;
                    this.dateToDo = this.toDoRequest.dateToDoCalendarFormatted;

                    this.getListToDoCategory(false);
                }
                else {

                    this.toDoRequest.userResponsibleSelected = { 
                        id: this.userLoggedGetters.id,
                        name: "Myself"
                    }

                    this.getListToDoCategory(true);
                }
            },

            async getStatus() {
                let listStatus = await this.$store.dispatch("serviceModule/ListStatus");

                if (this.toDoRequest.projectSelected != null && this.toDoRequest.projectSelected != undefined) {
                    this.listStatus = listStatus.filter(sts => sts.id >= this.toDoRequest.projectSelected.status).sort((a, b) => { return orderBy(a, b, 0, 'id') });

                    if (this.listStatus != null && this.listStatus != undefined) {
                        this.toDoRequest.statusSelected = this.listStatus[0];
                    }
                }
            },

            async getListToDoCategory(clearSelected = false) {
                this.listToDoCategory = [];

                if (clearSelected == true) {
                    this.toDoRequest.toDoCategorySelected = null;
                }

                if (this.toDoRequest.userResponsibleSelected != null && this.toDoRequest.userResponsibleSelected != undefined) {
                    this.listToDoCategory = await this.$store.dispatch("toDoCategoryModule/ListByUser", this.toDoRequest.userResponsibleSelected.id);

                    if (this.listToDoCategory != null && 
                        this.listToDoCategory != undefined && 
                        this.listToDoCategory.length == 1 &&
                        clearSelected == true) {
                        this.toDoRequest.toDoCategorySelected = this.listToDoCategory[0];
                    }
                }
            },

            async getLists() {
                
                this.listUser = await this.$store.dispatch("userModule/List");
                
                this.listUser.push({
                    id: this.userLoggedGetters.id,
                    name: 'Myself'
                })

                this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", '-');
            },

            async cancel() {
                this.$router.push({ path: "/toDo/toDoList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    this.toDoRequest.id = this.id;
                    this.toDoRequest.assignedToMe = 0;

                    if (this.toDoRequest.userResponsibleSelected.id !== this.userLoggedGetters.id) {
                        this.toDoRequest.idUserResponsible = this.toDoRequest.userResponsibleSelected.id;
                        this.toDoRequest.assignedToMe = 1;
                    }
                    else {
                        this.toDoRequest.idUserResponsible = this.userLoggedGetters.id;
                    }

                    if (this.toDoRequest.toDoCategorySelected !== null && this.toDoRequest.toDoCategorySelected !== undefined && this.toDoRequest.toDoCategorySelected.id !== -1) {
                        this.toDoRequest.idTodoCategory = this.toDoRequest.toDoCategorySelected.id;
                    }
                    else {
                        this.toDoRequest.idTodoCategory = 0;
                    }
                    
                    this.toDoRequest.system = 0;
                    this.toDoRequest.read = 0;
                    this.toDoRequest.dateToDo = this.dateToDo;

                    if (this.toDoRequest.urgent === null) {
                        this.toDoRequest.urgent = 0;
                    }
                    else {
                        if (this.toDoRequest.urgent == true) {
                            this.toDoRequest.urgent = 1;
                        }
                        else {
                            this.toDoRequest.urgent = 0;
                        }
                    }

                    if (this.toDoRequest.projectSelected !== null && this.toDoRequest.projectSelected !== undefined) {
                        this.toDoRequest.idServiceProject = this.toDoRequest.projectSelected.id;
                    }

                    if (this.toDoRequest.statusSelected !== null && this.toDoRequest.statusSelected !== undefined) {
                        this.toDoRequest.status = this.toDoRequest.statusSelected.id;
                    }

                    const result = await this.$store.dispatch("toDoModule/CreateUpdate", this.toDoRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.$router.push({ path: "/toDo/toDoList" });
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>
